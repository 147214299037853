import type { Resource } from 'i18next'

const buildResource = (context: __WebpackModuleApi.RequireContext) => {
  return context.keys().reduce<Resource>((acc, fileName) => {
    const namespace = fileName.replace('./', '').replace('.json', '')
    return {
      ...acc,
      [namespace]: context(fileName),
    }
  }, {})
}

const requireDev = require.context('../locales/dev', false, /\.json$/)
const requireSv = require.context('../locales/sv', false, /\.json$/)
const requireEn = require.context('../locales/en', false, /\.json$/)
const requireFi = require.context('../locales/fi', false, /\.json$/)
const requireFr = require.context('../locales/fr', false, /\.json$/)

export const sv = buildResource(requireSv)
export const en = buildResource(requireEn)
export const fi = buildResource(requireFi)
export const fr = buildResource(requireFr)
export const dev = buildResource(requireDev)
