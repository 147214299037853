import(/* webpackMode: "eager" */ "/opt/build/repo/apps/p2/src/app/globals.css");
;
import(/* webpackMode: "eager" */ "/opt/build/repo/apps/p2/src/app/fonts.css");
;
import(/* webpackMode: "eager", webpackExports: ["Providers"] */ "/opt/build/repo/apps/p2/src/app/providers.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["IntercomBubble"] */ "/opt/build/repo/apps/p2/src/ui-page-modules/intercom-settings/index.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["PageViewTracking"] */ "/opt/build/repo/apps/p2/src/ui-shared/page-view-tracking.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["GoogleAnalytics"] */ "/opt/build/repo/apps/p2/src/vendor/google-analytics.tsx");
