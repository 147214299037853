'use client'

import '../vendor/intercom-client'

import {
  ApolloProvider,
  LocaleProvider,
  ToastProvider,
  UserConsentProvider,
  useTrackClickEvent,
} from '@qasa/app'
import { HelmetProvider } from 'react-helmet-async'
import type { PropsWithChildren } from 'react'
import { QdsProvider } from '@qasa/qds-ui'

import { LanguageLoader } from '../translations/language-loader'
import { useCurrentLocale } from '../translations/use-current-locale'
import { CookieConsent } from '../ui-page-modules/cookie-consent'
import { Toasts } from '../ui-page-modules/toasts'
import { currentBrand } from '../brands'
import { blocketQdsOverrides } from '../ui-styles/blocket'
import { AuthProvider } from '../context/auth-context'
import { BRAND_CONFIG } from '../brands/brand-configurations'
import { useEmotionCache } from '../hooks/use-emotion-cache'
import { AuthDialogContextProvider } from '../context/auth-dialog-context'

export function Providers({ children }: PropsWithChildren) {
  useTrackClickEvent()
  const isBlocket = currentBrand === 'blocket'
  const qdsOverrides = isBlocket ? blocketQdsOverrides : undefined
  const locale = useCurrentLocale()
  const emotionCache = useEmotionCache()

  return (
    <HelmetProvider>
      <ApolloProvider>
        <LanguageLoader>
          <QdsProvider locale={locale} themeOverrides={qdsOverrides} emotionCache={emotionCache}>
            <LocaleProvider locale={BRAND_CONFIG.countryCode}>
              <UserConsentProvider>
                <ToastProvider>
                  <AuthProvider>
                    <AuthDialogContextProvider>{children}</AuthDialogContextProvider>
                    <CookieConsent />
                    <Toasts />
                  </AuthProvider>
                </ToastProvider>
              </UserConsentProvider>
            </LocaleProvider>
          </QdsProvider>
        </LanguageLoader>
      </ApolloProvider>
    </HelmetProvider>
  )
}
