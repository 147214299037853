import { Fragment, useEffect } from 'react'
import { initReactI18next } from 'react-i18next'
import i18next from 'i18next'
import { I18nextProvider } from '@qasa/app'
import moment from 'moment'
import Cookies from 'js-cookie'

import { useCurrentLocale } from '../use-current-locale'
import { currentBrand } from '../../brands'

import { dev, en, sv, fr, fi } from './build-resources'

const momentJsLocales = {
  en: () => Promise.resolve(),
  // @ts-expect-error
  fr: () => import('moment/locale/fr'),
  // @ts-expect-error
  sv: () => import('moment/locale/sv'),
  // @ts-expect-error
  fi: () => import('moment/locale/fi'),
}

const isServerSide = typeof window === 'undefined'

i18next.use(initReactI18next).init({
  resources: {
    dev,
    en,
    sv,
    fr,
    fi,
  },
  lng: undefined,
  fallbackLng: 'dev',
  interpolation: {
    escapeValue: false,
    skipOnVariables: false,
  },
  defaultNS: 'commons',
  compatibilityJSON: 'v3',
  preload: isServerSide ? ['dev', 'en', 'sv', 'fr', 'fi'] : [],
})

/**
 * This is to temporarily allow Finnish on qasa.com for select users (devs),
 * so we don't have to translate everything at once. Just set a cookie in devtools
 * with the name "finnish" and value "on"
 *
 */
const getFinnishLanguageWithCookieOnDotcom = () => {
  const shouldUseFinnish = currentBrand === 'dotcom' && Cookies.get('finnish') === 'on'
  return shouldUseFinnish ? 'fi' : null
}

export function LanguageLoader({ children }: { children: React.ReactNode }) {
  const lngFromLocale = useCurrentLocale()

  const lng = getFinnishLanguageWithCookieOnDotcom() || lngFromLocale

  useEffect(() => {
    momentJsLocales[lng]().then(() => {
      moment.locale(lng)
    })
  }, [lng])

  if (i18next.language !== lng) {
    i18next.changeLanguage(lng)
  }

  useEffect(() => {
    if (i18next.language === lng) {
      return
    }

    i18next.changeLanguage(lng)
  }, [lng])

  return (
    <Fragment key={lng}>
      <I18nextProvider currentLanguage={lng}>{children}</I18nextProvider>
    </Fragment>
  )
}
